.line {
  width: 0;
  height: 100px;
  position: relative;
}

.line:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 2px;
  content: '';
  position: absolute;
  background-color: gray;
}

.smallLine {
  height: 69px;
  position: relative;
}
.smallLine:before {
  width: 1px;
  opacity: 0.5;
}

.label {
  left: -19px;
  position: absolute;
  bottom: -30px;
  width: 69px;
}
