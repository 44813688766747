.react-quill-editor-wrapper {
  font-family: 'Roboto Flex', Roboto, Lato, sans-serif;
}

/** Toolbar */
.react-quill-editor-wrapper .ql-toolbar:after {
  display: none;
}
.react-quill-editor-wrapper .ql-toolbar {
  border-width: 2px;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: var(--md-ref-palette-neutral98);
  border-color: var(--md-ref-palette-neutral95);
}
.react-quill-editor-wrapper .ql-toolbar button[type='button'] {
  width: 25px;
  height: 25px;
  padding: 4px;
  margin-left: 4px;
  border-radius: 4px;
  font-family: 'Roboto Flex', Roboto, Lato, sans-serif;
}
.react-quill-editor-wrapper .ql-toolbar button.ql-active .ql-fill,
.react-quill-editor-wrapper .ql-toolbar button:hover .ql-fill {
  fill: var(--md-sys-color-on-surface-light);
}
.react-quill-editor-wrapper .ql-toolbar button.ql-active .ql-stroke,
.react-quill-editor-wrapper .ql-toolbar button:hover .ql-stroke {
  stroke: var(--md-sys-color-on-surface-light);
}
.react-quill-editor-wrapper .ql-toolbar button[type='button']:first-child {
  margin-left: 0;
}
.react-quill-editor-wrapper .ql-toolbar button.ql-active {
  color: var(--md-ref-palette-secondary40);
  background-color: var(--md-ref-palette-secondary95);
}
.react-quill-editor-wrapper .ql-toolbar button.ql-active .ql-fill {
  fill: var(--md-ref-palette-secondary40);
}
.react-quill-editor-wrapper .ql-toolbar button.ql-active .ql-stroke {
  stroke: var(--md-ref-palette-secondary40);
}
.react-quill-editor-wrapper .ql-toolbar .ql-formats {
  margin-right: 0;
}

/** Editor */
.react-quill-editor-wrapper .ql-editor {
  font-size: 16px;
  font-family: 'Roboto Flex', Roboto, Lato, sans-serif;
}
.react-quill-editor-wrapper .ql-editor {
  min-height: 116px;
  margin-bottom: 22px;
}
/* Container */
.react-quill-editor-wrapper .ql-container {
  border-width: 2px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: var(--md-ref-palette-neutral95);
  background-color: var(--md-sys-color-on-primary-light);
}

/* Playground */
.chatgpt-playground-open .ql-container {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/** Quill Mentions */
.ql-mention-list-container {
  z-index: 10;
  border-radius: 5px;
  user-select: none;
  max-height: 167px;
  overflow: hidden auto;
  background: var(--md-sys-color-on-primary-light);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid var(--md-ref-palette-neutral95);
}
.ql-mention-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Roboto Flex', Roboto, Lato, sans-serif;
}
.ql-mention-list-item {
  padding: 4px 5px;
  cursor: pointer;
  min-width: 160px;
}
.ql-mention-list-item:hover,
.ql-mention-list-item.selected {
  background-color: var(--md-ref-palette-neutral95);
}
.ql-mention-list-item-stack {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
}
.ql-mention-list-item-avatar {
  width: 25px;
  height: 25px;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  margin-right: 8px;
  border-radius: 25px;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  color: var(--md-ref-palette-neutral40);
  background-color: var(--md-ref-palette-neutral90);
}
.ql-mention-list-item-text {
  font-size: 14px;
}

/* Rich Text Content */
.rich-text-content .mention[data-mention-id],
.ql-editor .mention[data-mention-id] {
  padding: 0 3px;
  font-weight: 700;
  font-size: 13px;
  border-radius: 2px;
  display: inline-block;
  background: var(--md-ref-palette-neutral90);
}

/** Custom Bottom Toolbar Buttons */
.react-quill-bottom-toolbar .quill-custom-toolbar-button {
  width: 25px;
  height: 25px;
  margin-left: 4px;
  border-radius: 4px;
  line-height: 1;
  font-size: 18px;
  padding: 0 0 0 0;
}
.react-quill-bottom-toolbar
  .MuiIconButton-root.quill-custom-toolbar-button:hover,
.react-quill-bottom-toolbar
  .MuiIconButton-root.quill-custom-toolbar-button.active {
  transition: 200ms;
  background-color: var(--md-ref-palette-neutral95);
}
.react-quill-bottom-toolbar .MuiIconButton-root .MuiSvgIcon-root {
  font-size: 18px;
}
.react-quill-bottom-toolbar
  .MuiIconButton-root.quill-custom-toolbar-button:active {
  opacity: 0.5;
  transition: none;
}

/** Rich Editor for comments */
.rich-editor-comment-form .react-quill-editor-wrapper .ql-editor {
  min-height: 70px;
}

/** Dark Theme */
.dark-theme .react-quill-editor-wrapper {
  color: var(--md-sys-color-on-background-dark);
}
.dark-theme .react-quill-editor-wrapper .ql-toolbar .ql-stroke {
  stroke: #fff;
}
.dark-theme .react-quill-editor-wrapper .ql-toolbar .ql-fill {
  fill: #fff;
}
.dark-theme .react-quill-editor-wrapper .ql-toolbar {
  background: var(--md-ref-palette-neutral20);
  border-color: var(--md-ref-palette-neutral20);
}
.dark-theme .react-quill-editor-wrapper .ql-container {
  border-color: var(--md-ref-palette-neutral20);
  background-color: var(--md-sys-color-background-dark);
}

.dark-theme .react-quill-editor-wrapper .ql-toolbar button.ql-active .ql-fill,
.dark-theme .react-quill-editor-wrapper .ql-toolbar button:hover .ql-fill {
  fill: var(--md-sys-color-on-background-dark);
}
.dark-theme .react-quill-editor-wrapper .ql-toolbar button.ql-active .ql-stroke,
.dark-theme .react-quill-editor-wrapper .ql-toolbar button:hover .ql-stroke {
  stroke: var(--md-sys-color-on-background-dark);
}
.dark-theme .react-quill-editor-wrapper .ql-toolbar button.ql-active {
  background-color: var(--md-ref-palette-neutral30);
}

.dark-theme .react-quill-editor-wrapper .ql-toolbar button.ql-active .ql-fill {
  fill: var(--md-ref-palette-secondary90);
}
.dark-theme
  .react-quill-editor-wrapper
  .ql-toolbar
  button.ql-active
  .ql-stroke {
  stroke: var(--md-ref-palette-secondary90);
}
.dark-theme .react-quill-editor-wrapper .ql-editor.ql-blank:before {
  color: var(--md-ref-palette-neutral30);
}

/** Dark Theme for Mentions */
.dark-theme .ql-mention-list-container {
  border-color: var(--md-ref-palette-neutral30);
  background: var(--md-ref-palette-neutral20);
}
.dark-theme .ql-mention-list-item:hover,
.dark-theme .ql-mention-list-item.selected {
  background-color: var(--md-ref-palette-neutral30);
}
.dark-theme .ql-mention-list-item-avatar {
  color: var(--md-ref-palette-neutral40);
  background-color: var(--md-ref-palette-neutral90);
}
.dark-theme .rich-text-content .mention[data-mention-id],
.dark-theme .ql-editor .mention[data-mention-id] {
  background: var(--md-ref-palette-neutral20);
}

/** Dark Mode Custom Bottom Toolbar Buttons */
.dark-theme .react-quill-bottom-toolbar .quill-custom-toolbar-button:hover,
.dark-theme .react-quill-bottom-toolbar .quill-custom-toolbar-button.active {
  background-color: var(--md-ref-palette-neutral20);
}
