.reasonTruncate {
  @media only screen and (max-width: 1366px) {
    width: 210px;
  }

  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableRowCellText {
  font-size: 14px;
  font-weight: 500;
  padding-top: 2px;
}

.actionIconSize {
  font-size: 16px;
}
