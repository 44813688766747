.timeDetailsTableHeader {
  padding: 16px 24px;
  height: 50px;
  padding-left: 18px;
}

.timeDetailsTableHeaderCell {
  width: 0;
  flex-grow: 2;
  max-width: initial;
  flex-basis: initial;
}

.timeDetailsTableHeaderCellText {
  opacity: 0.5;
  letter-spacing: 0.4px;
}

.actionMaxWidth70 {
  max-width: 70;
}
