.schedule-days-list-container {
  position: relative;
}
.schedule-days-list-container-before,
.schedule-days-list-container-after {
  top: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0;
  width: 100px;
  display: block;
  position: absolute;
  pointer-events: none;
}
.schedule-days-list-container-before {
  left: 0;
}
.schedule-days-list-container-after {
  right: 0;
}

.schedule-days-list-container.before-active
  .schedule-days-list-container-before,
.schedule-days-list-container.after-active .schedule-days-list-container-after {
  opacity: 1;
}

.schedule-days-arrow-left,
.schedule-days-arrow-right {
  visibility: hidden;
}

.schedule-days-list-container.before-active .schedule-days-arrow-left,
.schedule-days-list-container.after-active .schedule-days-arrow-right {
  visibility: initial;
}
