.editModalHeaderTitle {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

.modalDateText {
  font-size: 14px;
  opacity: 0.6;
  font-weight: bold;
}
