.side-sheet-prompt-input {
  border: 0;
  width: 100%;
  resize: none;
  outline: none;
  color: inherit;
  display: block;
  padding: 20px;
  border-radius: 4px;
  background-color: transparent;
}

.dark-theme .side-sheet-prompt-input {
  background-color: var(--md-ref-palette-neutral20);
}
