:root {
  --calendar-event-pto-type-color: 140, 115, 170;
  --calendar-event-flex-holiday-type-color: 255, 191, 71;
  --calendar-event-uto-type-color: 51, 153, 255;
  --calendar-event-birthday-type-color: 255, 149, 0;
  --calendar-event-anniversary-type-color: 153, 102, 255;
  --calendar-event-others-type-color: 149, 149, 149;
}

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar {
  font-family: 'Roboto Flex', 'Lato', 'Helvetica Neue', sans-serif;
}

/** Toolbar */
.rbc-toolbar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.rbc-toolbar .rbc-btn-group {
  width: 300px;
  display: flex;
}
/* Last child here is Month/Week/Day */
.rbc-toolbar .rbc-btn-group:last-child {
  justify-content: flex-start;
}
/* Last child here is Today/Prev/Next */
.rbc-toolbar .rbc-btn-group:first-child {
  justify-content: flex-end;
}
.rbc-toolbar .rbc-btn-group:first-child .MuiSvgIcon-root {
  top: 1px;
  position: relative;
}
.rbc-toolbar .rbc-btn-group:first-child button:first-child {
  width: initial;
  min-width: initial;
  padding-left: 16px;
  padding-right: 16px;
}
.rbc-toolbar .rbc-btn-group:first-child button:not(:first-child) {
  width: 34px;
  margin: 0 4px;
  min-width: 34px;
  padding-left: 0;
  padding-right: 0;
}
.rbc-toolbar button {
  height: 34px;
  cursor: pointer;
  user-select: none;
  min-width: 94px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 20px;
  background-color: transparent;
  color: var(--md-sys-color-on-background-light);
  font-family: 'Roboto Flex', 'Lato', 'Helvetica Neue', sans-serif;
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 22px;
  font-weight: 500;
  color: var(--md-sys-color-on-background-light);
}
.rbc-calendar .rbc-toolbar {
  margin-bottom: 24px;
}
.rbc-toolbar button:hover {
  background-color: var(--md-ref-palette-neutral80);
}
.rbc-toolbar button:active {
  opacity: 0.8;
}
.rbc-toolbar .rbc-active:hover,
.rbc-toolbar .rbc-active {
  color: var(--md-ref-palette-neutral100);
  background-color: var(--md-ref-palette-secondary40);
}

/** Container */
.rbc-calendar .rbc-agenda-view,
.rbc-calendar .rbc-time-view,
.rbc-calendar .rbc-month-view {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--md-ref-palette-neutral90);
}
.rbc-calendar .rbc-time-view {
  flex: 1;
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
}
.rbc-calendar .rbc-month-view,
.rbc-calendar .rbc-time-view {
  /* min-height: calc(100vh - var(--app-toolbar-height) - 141px); */
  min-height: calc(100vh - var(--app-toolbar-height) - 200px);
}

/** RBC State */
.rbc-calendar.no-time-slot-calendar .rbc-time-view .rbc-time-content {
  display: none;
}
.rbc-calendar.no-time-slot-calendar .rbc-time-view .rbc-time-header-content {
  border-bottom: 0;
}
.rbc-calendar.no-time-slot-calendar .rbc-time-gutter,
.rbc-calendar.no-time-slot-calendar .rbc-time-header-gutter {
  display: none;
}
.rbc-calendar.no-time-slot-calendar .rbc-allday-cell .rbc-row-bg {
  left: 0;
  border-left: 0;
}
/* DAY CURRENT VIEW */
.no-time-slot-calendar.day-as-current-view .rbc-allday-cell .rbc-row-content {
  display: grid;
  padding: 8px 8px;
  grid-template-columns: repeat(3, 1fr);
}
/* DAY WEEK VIEW */
.no-time-slot-calendar.week-as-current-view .rbc-allday-cell .rbc-row-content {
}

/* rbc-button-link */
.rbc-button-link {
  border: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  font-family: 'Roboto Flex', 'Lato', 'Helvetica Neue', sans-serif;
}

/* Rows */
.rbc-row-bg,
.rbc-row {
  display: flex;
}
.rbc-row-bg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
}

/* Cells / Columns */
.rbc-date-cell {
  flex: 1;
  width: 0;
  border-left: 1px solid var(--md-ref-palette-neutral90);
}
.rbc-date-cell:first-child {
  border-left-color: transparent !important;
}

/* Headers */
.rbc-calendar .rbc-time-header-cell,
.rbc-calendar .rbc-month-header {
  z-index: 2;
  display: flex;
  position: relative;
  border-bottom: 1px solid var(--md-ref-palette-neutral90);
}
.rbc-calendar .rbc-header {
  flex: 1;
  width: 0;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: var(--md-ref-palette-neutral60);
  border-color: var(--md-ref-palette-neutral90);
  border-left: 1px solid var(--md-ref-palette-neutral90);
}
.rbc-calendar .rbc-header:first-child {
  border-left-color: transparent !important;
}
.rbc-time-header-cell .rbc-header {
  border-left: 0;
}

/* RDC DATE CELL */
.rbc-date-cell .rbc-button-link {
  text-align: right;
  font-weight: 400;
  color: var(--md-ref-palette-neutral60);
}

/* Row Month Row */
.rbc-month-row {
  flex: 1;
  position: relative;
  border-bottom: 1px solid var(--md-ref-palette-neutral90);
}
.rbc-month-row:last-child {
  border-bottom: 0;
}
.rbc-day-bg {
  flex: 1;
  border-left: 1px solid var(--md-ref-palette-neutral90);
}
.rbc-day-bg:first-child {
  border-left-color: transparent !important;
}
.rbc-off-range-bg {
  background-color: rgba(0, 0, 0, 0.03);
}
.rbc-calendar .rbc-today {
  background-color: var(--md-ref-palette-primary98);
}

.rbc-row-content {
  position: relative;
  min-height: 122px;
  padding-bottom: 16px;
}

/* Show More */
.rbc-show-more {
  padding: 4px;
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  background-color: transparent;
  color: var(--md-ref-palette-neutral60);
}
.rbc-show-more:hover {
  color: var(--md-ref-palette-neutral-variant40);
}

/* Event */
.rbc-calendar .rbc-event {
  outline: none;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  padding-left: 6px;
  border-radius: 4px;
}
.rbc-calendar .rbc-row-segment {
  padding: 4px 4px 0;
}
.rbc-month-view .rbc-event {
  cursor: pointer;
  user-select: none;
}

.pto-type-event {
  background-color: rgba(var(--calendar-event-pto-type-color), 0.5);
  box-shadow: 0 0 0 1px rgba(var(--calendar-event-pto-type-color), 0.1);
}
.pto-type-event:hover,
.pto-type-event.active {
  background-color: rgba(var(--calendar-event-pto-type-color), 0.6);
}
.pto-type-event.selected {
  background-color: rgba(var(--calendar-event-pto-type-color), 0.6);
}

.flex-holiday-type-event {
  background-color: rgba(var(--calendar-event-flex-holiday-type-color), 0.5);
  box-shadow: 0 0 0 1px rgba(var(--calendar-event-flex-holiday-type-color), 0.1);
}
.flex-holiday-type-event:hover,
.flex-holiday-type-event.active {
  background-color: rgba(var(--calendar-event-flex-holiday-type-color), 0.6);
}
.flex-holiday-type-event.selected {
  background-color: rgba(var(--calendar-event-flex-holiday-type-color), 0.6);
}

.uto-type-event {
  background-color: rgba(var(--calendar-event-uto-type-color), 0.5);
  box-shadow: 0 0 0 1px rgba(var(--calendar-event-uto-type-color), 0.1);
}
.uto-type-event:hover,
.uto-type-event.active {
  background-color: rgba(var(--calendar-event-uto-type-color), 0.6);
}
.uto-type-event.selected {
  background-color: rgba(var(--calendar-event-uto-type-color), 0.6);
}

.others-type-event {
  background-color: rgba(var(--calendar-event-others-type-color), 0.5);
  box-shadow: 0 0 0 1px rgba(var(--calendar-event-others-type-color), 0.1);
}
.others-type-event:hover,
.others-type-event.active {
  background-color: rgba(var(--calendar-event-others-type-color), 0.6);
}
.others-type-event.selected {
  background-color: rgba(var(--calendar-event-others-type-color), 0.6);
}

.birthday-type-event {
  background-color: rgba(var(--calendar-event-birthday-type-color), 0.5);
  box-shadow: 0 0 0 1px rgba(var(--calendar-event-birthday-type-color), 0.1);
}
.birthday-type-event:hover,
.birthday-type-event.active {
  background-color: rgba(var(--calendar-event-birthday-type-color), 0.6);
}
.birthday-type-event.selected {
  background-color: rgba(var(--calendar-event-birthday-type-color), 0.6);
}

.anniversary-type-event {
  background-color: rgba(var(--calendar-event-anniversary-type-color), 0.5);
  box-shadow: 0 0 0 1px rgba(var(--calendar-event-anniversary-type-color), 0.1);
}
.anniversary-type-event:hover,
.anniversary-type-event.active {
  background-color: rgba(var(--calendar-event-anniversary-type-color), 0.6);
}
.anniversary-type-event.selected {
  background-color: rgba(var(--calendar-event-anniversary-type-color), 0.6);
}

/* TIME CONTENT */
.rbc-calendar .rbc-time-content {
  display: flex;
  margin-top: -1px;
  position: relative;
  overflow: hidden auto;
  align-items: flex-start;
  border-top: 1px solid var(--md-ref-palette-neutral90);
}
.rbc-time-header-content {
  flex: 1;
  min-height: 20vh;
  border-bottom: 1px solid var(--md-ref-palette-neutral90);
}

.rbc-calendar .rbc-time-gutter,
.rbc-calendar .rbc-time-header-gutter {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;
}
.rbc-calendar .rbc-time-header-cell .rbc-header,
.rbc-calendar .rbc-time-header-cell .rbc-header button {
  font-weight: 500;
  color: var(--md-ref-palette-neutral40);
}
.rbc-time-column {
  width: 100%;
  display: flex;
  min-height: 100%;
  user-select: none;
  flex-direction: column;
}

.rbc-time-header-cell-single-day button.rbc-button-link {
  cursor: initial;
}

/* TIME SLOT */
.rbc-time-header {
  display: flex;
  margin-right: 0 !important;
}
.rbc-calendar .rbc-timeslot-group {
  height: 8.4vh;
  min-height: 100px;
  position: relative;
  border-top: 1px solid var(--md-ref-palette-neutral90);
}
.rbc-timeslot-group:first-child {
  border-top: 0;
}
.rbc-calendar .rbc-time-slot {
  left: 0;
  right: 0;
  height: 50%;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 0 2px 9px;
  position: absolute;
  color: var(--md-ref-palette-neutral60);
}
.rbc-calendar .rbc-time-slot:first-child {
  top: 0;
}
.rbc-calendar .rbc-time-slot:last-child {
  bottom: 0;
}
.rbc-calendar .rbc-day-slot {
  position: relative;
}
.rbc-calendar .rbc-time-gutter .rbc-timeslot-group {
  border-left: 0;
}
.rbc-allday-cell {
  padding-bottom: 4px;
}
.rbc-allday-cell .rbc-row-bg {
  right: 0;
  left: 70px;
  border-left: 1px solid var(--md-ref-palette-neutral90);
}
/** TODO: */
.rbc-events-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.rbc-events-container .rbc-event {
  position: absolute;
}

.rbc-current-time-indicator {
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  position: absolute;
  background-color: #74ad31;
}
.rbc-day-slot .rbc-timeslot-group .rbc-time-slot:first-child {
  background-color: rgb(0, 0, 0, 0.03);
  border-bottom: 1px solid rgb(0, 0, 0, 0.04);
}
.rbc-time-header-cell .rbc-today,
.rbc-time-header-cell-single-day .rbc-today,
.rbc-day-slot.rbc-today {
  background-color: transparent;
}

/* AGENDA */
.rbc-calendar .rbc-agenda-table {
  display: flex;
}
.rbc-agenda-view .rbc-agenda-content {
  flex: 1;
  height: 0;
  overflow: hidden auto;
}
.rbc-calendar .rbc-agenda-table thead,
.rbc-calendar .rbc-agenda-table tbody,
.rbc-calendar .rbc-agenda-table tr {
  width: 100%;
  display: flex;
}
.rbc-calendar .rbc-agenda-table tr {
  position: relative;
}
.rbc-calendar .rbc-agenda-table thead {
  border-bottom: 1px solid var(--md-ref-palette-neutral90);
}
.rbc-calendar .rbc-agenda-table th,
.rbc-calendar .rbc-agenda-table td {
  flex: initial;
}
.rbc-calendar .rbc-agenda-table tbody {
  flex-direction: column;
}
.rbc-calendar .rbc-agenda-table thead .rbc-header:first-child,
.rbc-calendar .rbc-agenda-table thead .rbc-header:first-child + th,
.rbc-calendar .rbc-agenda-table tbody td:first-child,
.rbc-calendar .rbc-agenda-table tbody td:first-child + td {
  width: 92px !important;
  min-width: 92px !important;
}
.rbc-calendar .rbc-agenda-table thead th:last-child,
.rbc-calendar .rbc-agenda-table tbody td:last-child {
  flex: 1;
  width: 0;
}
.rbc-calendar .rbc-agenda-table .rbc-header:first-child {
  border-color: transparent;
}
.rbc-calendar .rbc-agenda-table .rbc-agenda-date-cell,
.rbc-calendar .rbc-agenda-table .rbc-agenda-time-cell,
.rbc-calendar .rbc-agenda-table .rbc-agenda-event-cell {
  font-size: 12px;
  font-weight: 500;
  padding-top: 4px;
  padding-bottom: 4px;
  position: relative;
  border-left: 1px solid var(--md-ref-palette-neutral90);
}
.rbc-calendar .rbc-agenda-table .rbc-agenda-date-cell {
  top: 0;
  left: 0;
  padding-left: 8px;
  position: absolute;
  border-left-color: transparent;
}
.rbc-calendar .rbc-agenda-table .rbc-agenda-date-cell:before {
  top: 0;
  bottom: 0;
  left: -1px;
  content: '';
  width: 5000px;
  opacity: 0.2;
  position: absolute;
  background: var(--md-ref-palette-neutral90);
  border-bottom: 1px solid var(--md-ref-palette-neutral80);
}
.rbc-calendar .rbc-agenda-table .rbc-agenda-time-cell:first-child,
.rbc-calendar .rbc-agenda-table .rbc-agenda-time-cell:nth-child(2),
.rbc-calendar .rbc-agenda-table .rbc-agenda-time-cell {
  width: 184px !important;
  min-width: 184px !important;
  border-left-color: transparent;
}
.rbc-calendar .rbc-agenda-table .rbc-agenda-time-cell {
  position: relative;
  padding-left: 100px;
}
.rbc-calendar .rbc-agenda-table .rbc-agenda-time-cell:before {
  top: 0;
  bottom: 0;
  width: 1px;
  left: 91px;
  content: '';
  position: absolute;
  background: var(--md-ref-palette-neutral90);
}
.rbc-agenda-event-cell {
  padding-left: 8px;
}
.rbc-agenda-empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* DARK THEME */
.dark-theme .rbc-toolbar .rbc-toolbar-label {
  color: var(--md-sys-color-on-background-dark);
}
.dark-theme .rbc-toolbar button {
  color: var(--md-ref-palette-neutral90);
}
.dark-theme .rbc-toolbar button:hover {
  background-color: var(--md-ref-palette-secondary50);
}
.dark-theme .rbc-toolbar .rbc-active:hover,
.dark-theme .rbc-toolbar .rbc-active {
  color: var(--md-ref-palette-neutral10);
  background-color: var(--md-ref-palette-secondary80);
}

/** Container */
.dark-theme .rbc-calendar .rbc-time-header-cell,
.dark-theme .rbc-calendar .rbc-month-header,
.dark-theme .rbc-calendar .rbc-agenda-view,
.dark-theme .rbc-calendar .rbc-time-view,
.dark-theme .rbc-calendar .rbc-month-view,
.dark-theme .rbc-month-row,
.dark-theme .rbc-day-bg,
.dark-theme .rbc-calendar .rbc-header,
.dark-theme .rbc-date-cell,
.dark-theme .rbc-calendar .rbc-time-content,
.dark-theme .rbc-allday-cell .rbc-row-bg,
.dark-theme .rbc-calendar .rbc-timeslot-group {
  border-color: var(--md-ref-palette-neutral-variant30);
}

.dark-theme .rbc-off-range-bg {
  background-color: var(--md-ref-palette-neutral20);
}
.dark-theme .rbc-today {
  background-color: rgba(255, 255, 255, 0.02);
}

.dark-theme .rbc-day-slot .rbc-timeslot-group .rbc-time-slot:first-child {
  background-color: rgb(255, 255, 255, 0.01);
  border-bottom: 1px solid rgb(255, 255, 255, 0.01);
}

.dark-theme .rbc-time-header-cell-single-day button.rbc-button-link,
.dark-theme .rbc-today button.rbc-button-link,
.dark-theme .rbc-header button.rbc-button-link:hover {
  color: #fff;
}
